
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import base from "@/api/base.js";
import * as Yup from "yup";

export default defineComponent({
  name: "account-settings",

  components: {
    ErrorMessage,
    Field,
    Form
  },

  data() {
    const validation = Yup.object().shape({
      brand: Yup.string().required("필수값입니다."),
      ctgry_ty: Yup.string().required("필수값입니다."),
      ctgry_nm: Yup.string().required("필수값입니다."),
      // top_ctgry: Yup.string().required("필수값입니다."),
      // middlecategory: Yup.string().required("필수값입니다."),
      ctgry_use_yn: Yup.boolean().required("필수값입니다.")
    });

    return {
      category: {},
      qscList: [],
      brands: [],
      companys: {},
      topcategory: [],
      type: "",
      validation
    };
  },

  created() {
    base
      .getCompanyList()
      .then(res => {
        console.log(res);
        this.companys = res.data;
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);
        const value = Object.values(err.response.data);
        alert(value);
      });

    base
      .getBrandList()
      .then(res => {
        console.log(res);
        this.brands = res.data;
      })
      .catch(err => {
        console.log(err);
        console.log(err.response);
        const value = Object.values(err.response.data);
        alert(value);
      });
  },

  methods: {
    async submitForm() {
      console.log(this.category);
      await base
        .newCategory(this.category)
        .then(res => {
          console.log(res);
          if (res.status == 201) {
            alert("신규 점검 항목을 등록했습니다.");
            this.$router.push("/menu/check-items/category");
          } else {
            alert(res.status);
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    getTopCategory() {
      // if (this.category.ctgry_ty == "QSCCT10020") {
      //   this.type = "QSCCT10010";
      // } else if (this.category.ctgry_ty == "QSCCT10030") {
      //   this.type = "QSCCT10020";
      // }
      const data = {
        ctgry_ty: this.category.ctgry_ty,
        brand: this.category.brand
      };
      base
        .getCategoryHierarchy(data)
        .then(res => {
          console.log(res);
          this.topcategory = res.data;
        })
        .catch(err => {
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
    getSelected() {
      const score = this.topcategory
        .filter(list => list.id == this.category.top_ctgry)
        .map(list => list.top_category_nm);
      console.log(score);
      this.category.large = score;
    }
  }
});
